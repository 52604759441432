{
  "name": "customer-portal",
  "version": "1.0.17",
  "scripts": {
    "ng": "ng",
    "serve": "nx run customer-portal:serve:development",
    "start": "nx run customer-portal:serve:development",
    "serve:prod": "nx run customer-portal:serve:production",
    "build": "nx run customer-portal:build",
    "build:dev": "nx run customer-portal:build:development",
    "build:prod": "nx run customer-portal:build:production",
    "build:affected:dev": "nx affected:build --configuration=development",
    "build:affected:prod": "nx affected:build --configuration=production",
    "lint": "nx run customer-portal:lint",
    "lint:all": "nx run-many --all -t lint",
    "lint:affected": "nx affected:lint",
    "test": "nx run customer-portal:test",
    "test:all": "nx run-many --all -t test",
    "test:affected": "nx affected:test"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "17.3.0",
    "@angular/cdk": "17.3.0",
    "@angular/common": "17.3.0",
    "@angular/compiler": "17.3.0",
    "@angular/core": "17.3.0",
    "@angular/forms": "17.3.0",
    "@angular/material": "17.3.0",
    "@angular/material-moment-adapter": "17.3.0",
    "@angular/platform-browser": "17.3.0",
    "@angular/platform-browser-dynamic": "17.3.0",
    "@angular/router": "17.3.0",
    "@ngx-translate/core": "^15.0.0",
    "@ngx-translate/http-loader": "^8.0.0",
    "keycloak-angular": "^15.0.0",
    "keycloak-js": "^22.0.5",
    "moment": "^2.30.1",
    "ngx-file-drop": "^16.0.0",
    "ngx-image-compress": "^15.1.6",
    "react": "^18.2.0",
    "react-dom": "^18.2.0",
    "rxjs": "~7.8.0",
    "tslib": "^2.3.0",
    "zone.js": "~0.14.2"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "17.3.0",
    "@angular-devkit/core": "17.3.0",
    "@angular-devkit/schematics": "17.3.0",
    "@angular-eslint/eslint-plugin": "~17.3.0",
    "@angular-eslint/eslint-plugin-template": "~17.3.0",
    "@angular-eslint/template-parser": "~17.3.0",
    "@angular/cli": "~17.3.0",
    "@angular/compiler-cli": "17.3.0",
    "@angular/language-service": "~17.3.0",
    "@nx/angular": "18.3.5",
    "@nx/eslint": "18.3.5",
    "@nx/eslint-plugin": "^18.3.5",
    "@nx/jest": "18.3.5",
    "@nx/js": "18.3.5",
    "@nx/storybook": "18.3.5",
    "@nx/web": "18.3.5",
    "@nx/workspace": "18.3.5",
    "@schematics/angular": "~17.3.0",
    "@storybook/addon-actions": "^8.1.8",
    "@storybook/addon-essentials": "^8.1.8",
    "@storybook/addon-interactions": "^8.1.8",
    "@storybook/angular": "^8.1.8",
    "@storybook/core-server": "^8.1.8",
    "@storybook/manager-api": "^8.1.10",
    "@storybook/test": "^8.1.8",
    "@storybook/test-runner": "^0.18.2",
    "@storybook/theming": "^8.1.10",
    "@swc-node/register": "~1.8.0",
    "@swc/core": "~1.3.85",
    "@swc/helpers": "~0.5.2",
    "@types/jasmine": "~5.1.0",
    "@types/jest": "^29.4.0",
    "@types/node": "18.16.9",
    "@typescript-eslint/eslint-plugin": "^7.3.0",
    "@typescript-eslint/parser": "^7.3.0",
    "autoprefixer": "^10.4.0",
    "eslint": "~8.57.0",
    "jasmine-core": "~5.1.0",
    "jest": "^29.4.1",
    "jest-environment-jsdom": "^29.4.1",
    "jest-preset-angular": "~14.0.3",
    "jsonc-eslint-parser": "^2.1.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "lint-staged": "^15.1.0",
    "ng-packagr": "~17.3.0",
    "nx": "18.3.5",
    "postcss": "^8.4.5",
    "postcss-url": "~10.1.3",
    "prettier": "^2.6.2",
    "storybook": "^8.1.8",
    "ts-jest": "^29.1.0",
    "ts-node": "10.9.1",
    "typescript": "~5.2.2"
  }
}
